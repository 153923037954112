html {
  --section-background-color: linear-gradient(to bottom left,
      rgba(181, 147, 198, 0.582),
      rgba(133, 101, 249, 0.904));

  --image-gradient: linear-gradient(to bottom left,
      rgba(133, 107, 146, 0.678),
      rgba(101, 74, 235, 0.863));

  --imp-text-color: rgb(17, 255, 227);
  --imp-text-color2: rgba(180, 104, 204, 255);
  --imp-text-color3: linear-gradient(to left, rgb(160, 129, 221), rgb(113, 72, 236));
  --imp-text-color4: linear-gradient(to left, rgb(17, 255, 227), rgb(113, 72, 236));
}

#housecall-pro-chat-bubble {
  padding: 75px;
  display: none;
}

.purple {
  color: var(--imp-text-color2) !important;
}

.yellow {
  color: var(--imp-text-color) !important;
}

.purpleG {
  color: var(--imp-text-color3) !important;
}

.tealG {
  color: var(--imp-text-color4) !important;
}


button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ad5ffb;
  background-image: url(./../Assets/SS_LOGO.jpg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2d1950;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  /* background-color: aliceblue !important; */
  background-color: var(--imp-text-color2) !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;

}

.navbar {
  position: fixed !important;
  background-image: linear-gradient(to left, aliceblue, rgb(160, 129, 221));
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  opacity: 1 !important;
  left: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #181a27 !important;
  }

  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}

.navbar-brand {
  color: rgb(1, 0, 0) !important;
  /* padding: 0; */
  /* margin: 0; */
  /* width: 5.4em !important; */
}

.logo {
  position: fixed;
  top: 0;
  left: 0;
  height: 5.4rem !important;
  width: 5.4rem;
  margin: 0 !important;
  padding: 0 !important;
}

.navbar-nav .nav-link {
  color: var(--imp-text-color2) !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  /* width: 50%; */
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {

  /* .container {
    max-width: 50px;
  } */
  .nav-link {
    font-size: 0.2rem;
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c95bf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}




/* --------- */
/* Home section */
/* --------- */

.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* #tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  /* background-image: url(""); */
/* background-repeat: no-repeat; */
/* background-size: cover; */
/* background-position: 50% 50%; */
/* } */

/* #tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
} */

.home-header {
  /* margin-top: 100px; */
  padding-top: 100px !important;
  /* margin-left: 0px; */
  /* margin-right: 0px; */
}

.home-section {
  position: relative;
  height: 100vh;
  width: 100vw;
  margin-left: 0px;
  margin-right: 0px;
  z-index: -1;
  text-align: left;
  /* var(--image-gradient), */
  background-color: #000000;
  background-image: url(./../Assets/Images/Bulb-in-hand.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: auto;
}

.home-content {
  padding: 0 !important;
  color: white;
  margin-left: 0px;
  margin-right: 0px;
  text-align: left;
}

.home-body {
  padding-top: 20px;
  font-size: 2.8em !important;
  text-align: left;
}

.heading {
  font-size: 2.2em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.2em !important;
  padding-left: 50px !important;
}

.main-name {
  color: rgb(17, 255, 227);
}

.home-type {
  display: flexbox;
  margin: auto;
  text-align: right;
  padding-top: 100px;
  padding-right: 0;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: rgb(17, 255, 227) !important;
  font-weight: 600 !important;
}

.Typewriter__cursor {
  font-size: 2.2em !important;
  color: rgb(17, 255, 227) !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 2.2em !important;
    font-weight: 500 !important;
    position: absolute !important;
    display: none;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }

  #home {
    text-align: left;
    background-color: #000000;
    background-image: none;
    padding: 0 auto;
    margin: 0 auto;
    /* font-size: xx-small; */
  }

  .home-header {
    text-align: left;
    font-size: 0.8em;
    padding: 0 auto;
  }
}


.social {
  text-align: center !important;
  padding-top: 25px;
  color: rgb(0, 0, 0) !important;
}

.social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  /* padding-inline-start: 0 !important; */
}

.social-links li {
  position: inherit !important;
  display: inline-block !important;
  padding-right: 15px;
  text-align: center !important;
  text-decoration: none;
  color: var(--imp-text-color2) !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 2.2em !important;
  padding-left: 15px;
}

.social-icons {
  position: inherit !important;
  display: inline-block !important;
  color: var(--imp-text-color2) !important;
  /* background-color: var(--imp-text-color2) !important; */
  width: 60px !important;
  height: 60px !important;
  font-size: 2.2em !important;
  /* line-height: 4em !important; */
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.social-icons::before {
  content: "";
  position: inherit;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  /* background: #68187a; */
  transition: 0.5s;
  transform: scale(0.9);
  /* z-index: 1; */
}

.social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}



.icon-colour {
  /* color: var(--imp-text-color2) !important; */
  color: white !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: rgb(10, 4, 22);
  bottom: 0 !important;
  position: fixed !important;
  /* height: 10vh !important; */
  /* margin-top: 5px !important; */
  /* padding-top: 10px !important; */
  /* padding-bottom: 5px !important; */
}

.footer-copywright {
  text-align: center !important;
}

.footer-author {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer h3 {
    display: none;
  }
}

.footer h3 {
  /* font-size: 2.4em; */
  color: var(--imp-text-color2) !important;
  /* margin-top: 0.5em !important; */
  /* margin-bottom: 0.5em !important; */
}

.footer-icons {
  /* margin-top: 0.5em !important; */
  /* margin-bottom: 0.5em !important; */
  padding: 0 !important;
}

.blockquote-footer {
  color: rgba(180, 104, 204, 255) !important;
  /* color: white; */
}

/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: aliceblue !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: relative !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: rgb(0, 0, 0) !important;
  background-color: rgb(160, 129, 221) !important;
  /* background-color: transparent !important; */
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  position: relative;
  width: 360px;
  /* can be omitted for a regular non-lazy image */
  max-width: 100%;
}

.project-card-view img.card-img {
  object-fit: contain;
  opacity: 0;
  display: block;
  height: 60%;
  width: auto;
  transition: opacity .2s;
}

.project-card-view:hover img.card-img {
  display: none;
  opacity: 1;
}

.project-card-view img.card-img2 {
  object-fit: contain;
  opacity: 0;
  display: none;
  height: 60%;
  width: auto;
  transition: opacity .2s;
}

.project-card-view:hover img.card-img2 {
  display: flex;
  opacity: 1;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.card-img {
  height: 60%;
  width: auto;
}



.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

/* .blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
} */

.btn-primary {
  color: #000000 !important;
  background-color: aliceblue !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #000000 !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.brands-heading {
  color: rgb(0, 0, 0) !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* --------- */     /* About */     /* --------- */

.about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.about-section {
  position: relative !important;
  /* padding-top: 100px !important; */
  /* padding-bottom: 30px !important; */
  padding: 0px;
  /* background-image: var(--section-background-color) !important; */
  background-color: aliceblue;
  color: rgb(0, 0, 0) !important;
}

.about-activity {
  list-style: none !important;
  font-size: 1.2em !important;
  text-align: left !important;
  padding-left: 1px !important;
}

.about-description {
  color: var(--imp-text-color2) !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.imgStyle {

  /* width: 500px;
  height: 667px; */
  justify-content: "center";
  object-fit: cover;
}

.about-row {
  justify-content: "center";
  padding: "75px";
  padding-bottom: "10px";
}

@media (max-width: 767px) {
  .about-section {
    justify-content: "center";
    padding: 75px 0px 75px 0px;
    margin: 0px;
  }
  .about-wheel {
    display: none;
  }
}


/* ~~~~~~~~~~~~Contact page~~~~~~~~~~~~~~~~~~` */

* {
  box-sizing: border-box;
}

.contact-form {
  padding-top: 100px;
  padding-bottom: 15px;
  justify-content: center;
  max-width: 510px;
  margin: 50px auto;
}

.contact-form__container {
  position: relative !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  background-image: var(--section-background-color) !important;
  color: rgb(0, 0, 0) !important;
  border: 1px solid #f5f5f5;
  padding: 35px;
  margin: auto;
  box-shadow: 2px 2px 10px 0 #f5f5f5;
}

.contact-form__container p {
  line-height: 18px;
  font-size: 1.5em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 700;
  margin-bottom: 10px;
  margin: 0;
  text-align: center;
}

.contact-form__header h1 {
  /* color: #311c56;  */
  color: #623686;
  /* color: #4a4a4a; */
}

.contact-form__header p {
  margin-bottom: 0;
  color: #4a4a4a;
  color: #311c56;
}

.contact-form__header {
  background: #f7f7f7;
  padding: 2px 35px 31px;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

p {
  text-align: center;
  font-weight: 500;
}

input[type=text],
input[type=email],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 20px;
  resize: vertical;
}

input[type=submit] {
  background-color: #311c56;
  color: white;
  padding: 12px 20px;
  margin-top: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

textarea {
  height: 140px;
}

input[type=submit]:hover {
  background-color: #140c23;
}

/* .container {
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  border-radius: 5px;
    background-color: #f2f2f2;
    padding: 50px;
} */
.sucsess {
  margin: 20px 0;
  border: 1px solid green;
  padding: 7px;
  font-weight: bold;
}

.error {
  margin: 20px 0;
  border: 1px solid red;
  padding: 7px;
  font-weight: bold;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}

@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.fork-btn-inner::after {
  display: none !important;
}


/*~~~~~~~~~~~~~~~~Services~~~~~~~~~~~~~~~~~~*/
.services {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
  font-size: 1.1em !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

.services-section {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  /* var(--image-gradient), */
  background-color: aliceblue;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.services-content {
  padding: 2rem 0 2rem !important;
  /* background-image: linear-gradient(to left, rgb(160, 129, 221), rgb(113, 72, 236)); */
  color: linear-gradient(to left, rgb(160, 129, 221), rgb(113, 72, 236));
  font-size: 1.6em !important;
  text-align: left;
}

.heading {
  font-size: 4.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 4.4em !important;
  padding-left: 45px !important;
}

.main-name {
  /* color: rgb(17, 255, 227); */
  color: linear-gradient(to left, rgb(17, 255, 227), rgb(113, 72, 236));
  ;
}


/* css to reset all the designs */

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap'); */

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
} */

/* css to reset all the designs */

/* add styles on elements */
/* 
.header {
    border-bottom: 1px solid #E2E8F0;
    background-color: #222;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 1.5rem;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
    background-color: #fff;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    margin-left: 5rem;
}

.nav-link {
    font-size: 1.6rem;
    font-size: 2rem;
    font-weight: 400;
    color: #475569;
    color: #fff;
}

.nav-link:hover {
    color: #482ff7;
}

.nav-logo {
    font-size: 2.1rem;
    font-size: 3rem;
    font-weight: 500;
    color: #482ff7;
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .nav-menu {
        position: fixed;
        left: -100%;
        top: 5rem;
        flex-direction: column;
        background-color: #fff
        background-color: #222;
        width: 100%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
    }

    .nav-link {
        color: #fff;
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-item {
        margin: 2.5rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

} */

/* add styles on elements */